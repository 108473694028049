.sendbird-channel-list {
  height: 100% !important;
}

.sendbird-channel-preview {
  padding: 12px !important;
}

.sendbird-channel-preview:hover {
  background-color: #f9fafb !important;
}

.sendbird-channel-preview__content__upper {
  margin-bottom: 4px !important;
}

.sendbird-channel-preview__content__lower {
  margin-top: 0 !important;
}

.sendbird-message-input {
  padding: 12px !important;
  border-top: 1px solid #e5e7eb !important;
}

.sendbird-message-input__input {
  border-radius: 0.5rem !important;
}

.sendbird-message-content {
  padding: 8px 12px !important;
} 