.report-container {
    position: relative;
    width: 100% !important;
    height: calc(92vh) !important; /* Account for header (64px) + padding (48px) + margins */
    min-height: 400px;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  /* Add responsive adjustments if needed */
  @media (max-width: 768px) {
    .report-container {
      height: calc(100vh - 80px) !important; /* Adjust for mobile */
    }
  } 