@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Layout */
  .settings-container {
    @apply space-y-6;
  }

  /* Header */
  .settings-header {
    @apply flex items-center gap-4;
  }

  .settings-header-icon {
    @apply p-3 rounded-full;
  }

  .settings-title {
    @apply text-2xl font-semibold text-gray-900 dark:text-white;
  }

  .settings-subtitle {
    @apply text-gray-500 dark:text-gray-400;
  }

  /* Card */
  .settings-card {
    @apply bg-white dark:bg-gray-800/50 backdrop-blur-sm rounded-xl 
           border border-gray-200/50 dark:border-white/10 
           p-6 space-y-6;
  }

  /* Form Elements */
  .settings-input {
    @apply w-full px-4 py-2 
           bg-white dark:bg-gray-700 
           border border-gray-300 dark:border-gray-600 
           rounded-lg text-gray-900 dark:text-white 
           focus:ring-2 focus:ring-indigo-500 
           placeholder-gray-500 dark:placeholder-gray-400;
  }

  .settings-select {
    @apply w-full px-4 py-2 
           bg-white dark:bg-gray-700 
           border border-gray-300 dark:border-gray-600 
           rounded-lg text-gray-900 dark:text-white 
           focus:ring-2 focus:ring-indigo-500;
  }

  /* Buttons */
  .settings-button {
    @apply flex items-center gap-2 px-4 py-2 
           bg-indigo-500 hover:bg-indigo-600 
           text-white rounded-lg transition-colors;
  }

  /* Dividers */
  .settings-divider {
    @apply pt-6 border-t border-gray-200 dark:border-gray-700;
  }

  /* Toggle Switch - Optional if you want a consistent style */
  .settings-toggle {
    @apply w-11 h-6 
           bg-gray-200 dark:bg-gray-700 
           peer-focus:ring-4 peer-focus:ring-indigo-500/20 
           rounded-full peer 
           peer-checked:after:translate-x-full 
           after:content-[''] after:absolute after:top-[2px] after:start-[2px] 
           after:bg-white after:border-gray-300 after:border 
           after:rounded-full after:h-5 after:w-5 after:transition-all 
           peer-checked:bg-indigo-500;
  }

  /* Text styles for consistency */
  .settings-label {
    @apply block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1;
  }

  .settings-text {
    @apply text-gray-900 dark:text-white;
  }

  .settings-text-secondary {
    @apply text-gray-500 dark:text-gray-400 text-sm;
  }
} 